/*
   1.0 Color system
   2.0 RTL Setting
   3.0 Component Color Setting 
   4.0 Grid & Container Setting
   5.0 Body Setting
   6.0 App Component Setting
   7.0 z-index Setting
   8.0 Font Setting
   9.0 Radius Setting
  10.0 Caret Setting
  11.0 Heading / Paragraph Setting
  12.0 Pagination Setting
  13.0 Button Setting
  14.0 Form Component Setting
  15.0 Input Setting
  16.0 Table Setting
  17.0 Modal Setting
  18.0 List Group Setting
  19.0 Tabs Setting
  20.0 Alert Setting
  21.0 Card Setting
  22.0 Dropown Setting
  23.0 Progress Setting
*/
/* Perfect Scrollbar */
.ps .ps__rail-x {
  z-index: 10; }
  .ps .ps__rail-x:focus, .ps .ps__rail-x:hover, .ps .ps__rail-x.ps--clicking {
    background: none; }

.ps .ps__rail-y {
  z-index: 10;
  width: 6px !important;
  background: none !important; }
  .ps .ps__rail-y:focus, .ps .ps__rail-y:hover, .ps .ps__rail-y.ps--clicking {
    width: 6px !important;
    background: none !important; }
    .ps .ps__rail-y:focus .ps__thumb-y,
    .ps .ps__rail-y:focus > .ps__thumb_y, .ps .ps__rail-y:hover .ps__thumb-y,
    .ps .ps__rail-y:hover > .ps__thumb_y, .ps .ps__rail-y.ps--clicking .ps__thumb-y,
    .ps .ps__rail-y.ps--clicking > .ps__thumb_y {
      width: 6px !important; }

.page-sidebar-minified .sidebar .ps .ps__rail-x,
.page-sidebar-minified .sidebar .ps .ps__rail-y {
  display: none !important; }

/* Countdown Timer */
.countdown-section {
  display: block;
  text-align: center; }
  .countdown-show1 .countdown-section {
    width: 98%; }
  .countdown-show2 .countdown-section {
    width: 48%; }
  .countdown-show3 .countdown-section {
    width: 32.5%; }
  .countdown-show4 .countdown-section {
    width: 24.5%; }
  .countdown-show5 .countdown-section {
    width: 19.5%; }
  .countdown-show6 .countdown-section {
    width: 16.25%; }
  .countdown-show7 .countdown-section {
    width: 14%; }

.countdown-period {
  display: block; }

/* Calendar */
.fc .fc-toolbar {
  display: block; }
  .fc .fc-toolbar > * > * {
    float: none; }

/* Material Header Sort */
.mat-sort-header-sorted .mat-sort-header-button:focus {
  outline: none; }

/* Ng Bootstrap Datepicker */
ngb-datepicker {
  border: none; }
  ngb-datepicker.dropdown-menu {
    padding: 5px;
    -webkit-border-radius: 6px;
    border-radius: 6px; }
  ngb-datepicker .ngb-dp-header {
    background: none; }
    ngb-datepicker .ngb-dp-header ngb-datepicker-navigation-select > .custom-select {
      font-size: 12px;
      height: 26px;
      margin: 0 5px; }
  ngb-datepicker .ngb-dp-months .ngb-dp-month ngb-datepicker-month-view .ngb-dp-week {
    border: none;
    background: none !important; }
    ngb-datepicker .ngb-dp-months .ngb-dp-month ngb-datepicker-month-view .ngb-dp-week .ngb-dp-weekday {
      font-style: initial;
      font-size: 12px;
      font-weight: bold;
      color: #2d353c; }
    ngb-datepicker .ngb-dp-months .ngb-dp-month ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day {
      font-weight: 600; }
      ngb-datepicker .ngb-dp-months .ngb-dp-month ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day:focus {
        outline: none; }
      ngb-datepicker .ngb-dp-months .ngb-dp-month ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light {
        color: #6f8293; }
        ngb-datepicker .ngb-dp-months .ngb-dp-month ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:hover, ngb-datepicker .ngb-dp-months .ngb-dp-month ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:focus, ngb-datepicker .ngb-dp-months .ngb-dp-month ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:active {
          background: #f2f4f5;
          color: #2d353c; }
        ngb-datepicker .ngb-dp-months .ngb-dp-month ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:focus, ngb-datepicker .ngb-dp-months .ngb-dp-month ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light:active, ngb-datepicker .ngb-dp-months .ngb-dp-month ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light.active {
          outline: none !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important; }
        ngb-datepicker .ngb-dp-months .ngb-dp-month ngb-datepicker-month-view .ngb-dp-week .ngb-dp-day .btn-light.bg-primary {
          color: #FFFFFF;
          background: #348fe2 !important; }

/* Tag Input */
.ng2-tag-input {
  border: 1px solid #e4e7ea !important;
  padding: 3px 12px !important;
  min-height: 34px !important;
  -webkit-border-radius: 4px;
  border-radius: 4px; }
  .ng2-tag-input .ng2-tags-container tag {
    font-family: inherit;
    font-size: 12px;
    letter-spacing: 0;
    font-weight: 600;
    padding: 3px 8px;
    height: auto;
    line-height: 16px;
    background: #348fe2;
    color: #FFFFFF;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    .ng2-tag-input .ng2-tags-container tag svg {
      height: 16px !important; }
  .ng2-tag-input .ng2-tags-container .ng2-tag-input__text-input {
    height: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #2d353c; }
    .ng2-tag-input .ng2-tags-container .ng2-tag-input__text-input::-webkit-input-placeholder {
      color: #6f8293; }
    .ng2-tag-input .ng2-tags-container .ng2-tag-input__text-input::-moz-placeholder {
      color: #6f8293; }
    .ng2-tag-input .ng2-tags-container .ng2-tag-input__text-input:-ms-input-placeholder {
      color: #6f8293; }
    .ng2-tag-input .ng2-tags-container .ng2-tag-input__text-input:-moz-placeholder {
      color: #6f8293; }

.inbox .email-to .form-control {
  height: auto;
  min-height: 34px; }
  .inbox .email-to .form-control .ng2-tag-input {
    height: auto !important;
    border: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: -5px;
    margin-bottom: -5px; }

/* Angular Calendar */
.angular-calendar-inline .cal-month-view .cal-header {
  border-bottom: 1px solid #e4e7ea; }
  .angular-calendar-inline .cal-month-view .cal-header .cal-cell {
    font-size: 10px;
    text-transform: uppercase;
    color: #2d353c;
    padding: 5px 10px; }

.angular-calendar-inline .cal-month-view .cal-days {
  border: none; }
  .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row:hover {
    background: none; }
  .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row .cal-cell {
    position: relative;
    min-height: initial; }
    .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row .cal-cell:hover, .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row .cal-cell:focus {
      background: #f2f4f5;
      color: #2d353c; }
    .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row .cal-cell .cal-cell-top {
      position: relative;
      min-height: initial; }
      .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row .cal-cell .cal-cell-top .cal-day-number {
        color: #6f8293;
        font-size: 12px;
        font-weight: 600;
        margin-right: 3px;
        margin-bottom: 3px;
        margin-top: 20px;
        min-width: 20px;
        min-height: 20px;
        opacity: 1;
        -webkit-border-radius: 20px;
        border-radius: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
      .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row .cal-cell .cal-cell-top .cal-day-badge {
        text-indent: 20px;
        width: 5px;
        height: 5px;
        min-width: 3px;
        padding: 0;
        overflow: hidden;
        position: absolute;
        bottom: 25px;
        margin: 0;
        right: 11px;
        background: #ff5b57; }
    .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row .cal-cell .cal-events {
      position: absolute;
      top: 0;
      left: 0;
      right: 0; }
      .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row .cal-cell .cal-events .cal-event {
        width: 5px;
        height: 5px;
        display: inline-block;
        margin: 1px;
        background: #a9b5bf;
        -webkit-border-radius: 50%;
        border-radius: 50%; }
    .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row .cal-cell.cal-today {
      background: none; }
      .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row .cal-cell.cal-today .cal-cell-top .cal-day-number {
        background: #2d353c;
        color: #FFFFFF; }
    .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row .cal-cell.cal-event-highlight {
      background: #f2f4f5 !important; }
    .angular-calendar-inline .cal-month-view .cal-days .cal-cell-row .cal-cell.cal-day-cell:not(:last-child) {
      border-right-color: #e4e7ea; }

/* Ngx Loading bar */
#loading-bar-spinner {
  color: #00acac !important; }
  .loading-bar-fixed #loading-bar-spinner {
    top: 63px !important;
    right: 10px !important;
    left: auto !important; }

#loading-bar .bar {
  background: #00acac !important;
  height: 3px !important; }
  .loading-bar-fixed #loading-bar .bar {
    top: 50px !important; }
  #loading-bar .bar .peg {
    -webkit-box-shadow: none;
    box-shadow: none; }
